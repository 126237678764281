import React, { useContext } from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import MediaSlider
  from '@shipae/components-sandbox/component/media-slider';
import { black }
  from '@shipae/components-sandbox/component/colors';
import RichText
  from '@shipae/components-sandbox/component/rich-text';
import PrimaryButton
  from '@shipae/components-sandbox/component/buttons/primary';
import GtArrowIcon from '@shipae/components-sandbox/component/icons/gt-arrow';

import {
  MediaSlider as Type,
} from 'src/infrastructure/gatsby/types/media-slider';
import { calculateBreadcrumbs } from 'src/views/utils/calculate-breadcrumbs';
import { PageContext } from 'src/views/templates/context';
import styled from 'styled-components';
import { useTranslation } from 'src/views/misc/localization';
import SmartLink from '../smart-link';
import {
  CtaButtonContainer,
  ImageBg,
  RichTextThemeEx,
} from '../media/styles';
import { CtaContainerExtended } from './styles';
import gloabalReactMdOptions from '../shared/react-makdown-options';
import { ROUTES } from '../../constants/ROUTES';

type Props = Omit<Type, 'type'>;

const Section = styled.section``;

const MediaSliderComponent: React.FC<Props> = ({
  id,
  sectionId = '',
  showBreadcrumbs,
  enableAutoplay,
  autoplaySpeedMilliseconds,
  enableInfiniteSliding,
  showDots,
  slides = [],
  mobileDotsColor = '#000',
}) => {
  const pageContext = useContext(PageContext);
  const { locale, t } = useTranslation();

  return (
    <Section id={sectionId} data-ga={`MediaSlider-${ id }`}>
      <MediaSlider
        isRtl={locale === 'ar'}
        breadcrumbsHome={{
          label: t('home'),
          url: ROUTES[locale].HOME,
        }}
        breadcrumbsLinks={
          showBreadcrumbs
            ? calculateBreadcrumbs({
              parentTitle: pageContext.pageSettings.parentPageTitle,
              parentPath: pageContext.pageSettings.parentPagePath,
              title: pageContext.title,
            })
            : null
        }
        linkComponent={SmartLink}
        enableAutoplay={enableAutoplay}
        autoplaySpeedMilliseconds={parseInt(autoplaySpeedMilliseconds, 10)}
        enableInfiniteSliding={enableInfiniteSliding}
        showDots={showDots}
        mobileDotsColor={mobileDotsColor}
        slides={slides.map((slide) => ({
          backgroundImage: (
            <ImageBg
              fluid={
                slide.backgroundImage?.localFile?.childImageSharp?.fluid || []
              }
              alt={slide.backgroundImage?.alternativeText || ''}
              objectFit="cover"
              objectPosition="center center"
            />
          ),
          content: {
            fontColor: slide.contentFontColor || black(),
            padding: '9rem calc((100% - var(--container-width)) / 2)',
            node: (
              <RichText
                theme={RichTextThemeEx}
              >
                <ReactMarkdown
                  options={gloabalReactMdOptions}
                >
                  {slide.content || ''}
                </ReactMarkdown>
              </RichText>
            ),
          },
          mobileHideCallsToAction: false,
          cta: (
            <CtaContainerExtended
              clr={slide.contentFontColor}
              mobileClr={black()}
            >
              {slide.callsToAction ? slide.callsToAction.map((cta) => (
                /* eslint-disable-next-line no-nested-ternary */
                cta.type === 'button' ? (
                  cta.color ? (
                    <CtaButtonContainer bgcolor={cta.color}>
                      <PrimaryButton
                        key={cta.id}
                        href={cta.link}
                        linkComponent={SmartLink}
                      >
                        {cta.label}
                      </PrimaryButton>
                    </CtaButtonContainer>
                  ) : (
                    <PrimaryButton
                      key={cta.id}
                      href={cta.link}
                      linkComponent={SmartLink}
                    >
                      {cta.label}
                    </PrimaryButton>
                  )
                ) : (
                  cta.type === 'underlineLink' ? (
                    <SmartLink
                      key={cta.id}
                      href={cta.link || '#'}
                    >
                      {cta.label}
                    </SmartLink>
                  ) : (
                    <SmartLink
                      key={cta.id}
                      href={cta.link || '#'}
                    >
                      <GtArrowIcon
                        direction="bottom"
                        color={cta.color || black()}
                      />
                    </SmartLink>
                  )
                )
              )) : null}
            </CtaContainerExtended>
          ),
        }))}
      />
    </Section>
  );
};

export default MediaSliderComponent;
