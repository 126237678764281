import styled from 'styled-components';
import { media } from 'src/views/theme';
import { CtaContainer } from '../media/styles';

export const CtaContainerExtended = styled(CtaContainer)`
  ${ media.md } {
    margin-bottom: 0;
    padding: 0;
  }
`;
